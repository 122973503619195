import { forwardRef } from 'react';
import { Button as B, ButtonProps as BP } from '@chakra-ui/react';

import { useTranslation } from '../i18n';

export type ButtonProps = BP;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { t } = useTranslation();

    return (
      <B
        ref={ref}
        loadingText={t('notification.loading', 'Loading, please wait…')}
        borderRadius="full"
        color={
          props.variant === 'ghost' || props.variant === 'link'
            ? 'brand.500'
            : props.variant === 'outline'
            ? 'black.500'
            : 'white'
        }
        _focusVisible={{
          outline: 'none',
        }}
        _active={{
          _disabled: {
            background: 'gray.300',
          },
          background: () => {
            switch (props.variant || props.colorScheme) {
              case 'ghost':
                return 'transparent';
              case 'link':
                return 'transparent';
              case 'black':
                return 'black.400';
              case 'outline':
                return 'black.500';
              default:
                return 'brand.600';
            }
          },
        }}
        _hover={{
          _disabled: {
            background: 'gray.300',
          },
          background: () => {
            switch (props.variant || props.colorScheme) {
              case 'ghost':
                return 'transparent';
              case 'link':
                return 'transparent';
              case 'black':
                return 'black.400';
              case 'outline':
                return 'black.500';
              default:
                return 'brand.600';
            }
          },
          color:
            props.variant === 'ghost' || props.variant === 'link'
              ? 'brand.600'
              : 'white',
        }}
        _disabled={{
          background: 'gray.300',
          border: 'none',
          color: 'white',
        }}
        {...props}
      />
    );
  },
);
