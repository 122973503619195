import { FC } from 'react';
import { Box, Heading, VStack, Text } from '@chakra-ui/react';

import { useTranslation } from '../i18n';
import { HeroPage } from '../ui-components/HeroPage';
import { LinkButton } from '../ui-components/LinkButton';

export type ForgotPasswordVerifyEmailPageProps = {};

export const ForgotPasswordVerifyEmailPage: FC<
  ForgotPasswordVerifyEmailPageProps
> = (props) => {
  const { t } = useTranslation();

  return (
    <HeroPage>
      <VStack spacing={3} height="full">
        <VStack textAlign="center">
          <Heading>
            {t('title.forgotPassword.checkEmail', 'Verify your email')}
          </Heading>
          <Text>
            {t(
              'forgotPassword.checkEmail.subheading',
              'A password reset link has been sent to your email.',
            )}
          </Text>
        </VStack>
      </VStack>

      <Box>
        <LinkButton width="full" to="/login">
          {t('button.login', 'LOGIN')}
        </LinkButton>
      </Box>
    </HeroPage>
  );
};
