import {
  Heading,
  VStack,
  Text,
  Stack,
  Radio,
  Center,
  Wrap,
  Input,
  FormErrorMessage,
  FormControl,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { HookFormRadioGroup } from '../../form-components/HookFormRadioGroup';
import { useTranslation } from '../../i18n';
import { Button } from '../../ui-components/Button';
import { OnboardingFormStep2Fields } from '../OnboardingPage';

export const UserRoleInfoStep = (props: {
  onSubmit: () => void;
  onBack: () => void;
}) => {
  const { t } = useTranslation();

  const {
    control,
    watch,
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useFormContext<OnboardingFormStep2Fields>();

  const onSubmit = () => {
    props.onSubmit();
  };

  const userRole = watch('userRole');

  return (
    <Stack spacing={8}>
      <VStack textAlign="center">
        <Heading size="lg">
          {t(
            'onboarding.userRole.title',
            'What option best describes your role?',
          )}
        </Heading>
        <Text>
          {t(
            'onboarding.questionaireExplanation',
            'We’ll help you get started based on your answers.',
          )}
        </Text>
      </VStack>
      <Stack
        as="form"
        spacing={6}
        onSubmit={handleSubmit(() => {
          onSubmit();
        })}
      >
        <HookFormRadioGroup
          name="userRole"
          labelProps={{
            hidden: true,
            children: t('formlabel.company.userRole', 'User role'),
          }}
          controllerProps={{ control, rules: { required: true } }}
        >
          <Center>
            <Stack spacing={4}>
              <Radio value="MARKETING_MANAGER">
                {t(
                  'input.company.userRole.marketingManager',
                  'Marketing Manager',
                )}
              </Radio>
              <Radio value="CEO">
                {t('input.company.userRole.ceo', 'CEO')}
              </Radio>
              <Radio value="ENTREPRENEUR">
                {t('input.company.userRole.entrepreneur', 'Entrepreneur')}
              </Radio>
              <Radio value="DESIGNER">
                {t('input.company.userRole.designer', 'Designer')}
              </Radio>

              <Stack>
                <Radio value="OTHER">
                  {t('input.company.target.other', 'Other')}
                </Radio>
                {userRole === 'OTHER' && (
                  <FormControl
                    isInvalid={Boolean(errors.userRoleAdditionalDetails)}
                  >
                    <Input
                      variant="flushed"
                      placeholder={t(
                        'placeholder.company.userRole',
                        'Tell us more here',
                      )}
                      {...register('userRoleAdditionalDetails', {
                        shouldUnregister: true,
                        maxLength: {
                          value: 50,
                          message: t('formvalidation.maxLength', {
                            defaultValue:
                              'Maximum length should be {{maxLength}}',
                            maxLength: 50,
                          }),
                        },
                      })}
                    />
                    {errors.userRoleAdditionalDetails && (
                      <FormErrorMessage>
                        {errors.userRoleAdditionalDetails?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )}
              </Stack>
            </Stack>
          </Center>
        </HookFormRadioGroup>

        <Wrap width="full" minWidth="600px">
          <Button
            variant="outline"
            onClick={() => {
              reset();
              props.onBack();
            }}
            flex={1}
          >
            {t('button.back', 'BACK')}
          </Button>

          <Button type="submit" isDisabled={!userRole} flex={1}>
            {t('button.continue', 'CONTINUE')}
          </Button>
        </Wrap>
      </Stack>
    </Stack>
  );
};
