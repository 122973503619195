import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
} from '@chakra-ui/react';
import { FC, MutableRefObject } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from '../i18n';
import { trimmedRequired } from '../validators';

export type EmailFieldProps = InputProps & {
  emailKey?: string;
  label?: string;
  inputRef?: MutableRefObject<HTMLInputElement>;
};

export const EmailField: FC<EmailFieldProps> = (props) => {
  const { t, i18n } = useTranslation();
  const {
    emailKey = 'email',
    label = t('formlabel.email', 'Email address'),
    inputRef,
    ...inputProps
  } = props;
  const {
    register,
    formState: { errors },
    reset,
  } = useFormContext();

  i18n.on('languageChanged', () => {
    reset();
  });

  const emailProps = register(emailKey, {
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: t(
        'formvalidation.format',
        'Wrong format. Please enter correct email',
        {
          type: i18n
            .t<string>('formlabel.email', 'email address')
            .toLocaleLowerCase(),
        },
      ),
    },
    validate: {
      trimmedRequired,
    },
  });

  const error = errors[emailKey];

  return (
    <FormControl isInvalid={Boolean(error)}>
      <FormLabel htmlFor={emailKey}>{label}</FormLabel>
      <Input
        {...inputProps}
        id={emailKey}
        borderRadius="full"
        autoComplete="email"
        placeholder={t('placeholder.email', 'mathilda.muster@gmail.com')}
        {...emailProps}
        ref={(el) => {
          if (inputRef) {
            inputRef.current = el!;
          }

          emailProps.ref(el);
        }}
      />
      {error && <FormErrorMessage>{error.message as string}</FormErrorMessage>}
    </FormControl>
  );
};
