import { FC } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputProps,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from '../i18n';

export type NewPasswordConfirmationFieldProps = InputProps & {
  passwordKey?: string;
  passwordConfirmationKey?: string;
};

export const NewPasswordConfirmationField: FC<
  NewPasswordConfirmationFieldProps
> = (props) => {
  const {
    passwordKey = 'password',
    passwordConfirmationKey = 'passwordConfirmation',
    ...inputProps
  } = props;

  const { t, i18n } = useTranslation();
  const {
    register,
    watch,
    formState: { errors },
    reset,
  } = useFormContext();

  i18n.on('languageChanged', () => {
    reset();
  });

  const password = watch(passwordKey);

  const renderPasswordHelperText = () => {
    if (!errors[passwordConfirmationKey]) {
      return <FormHelperText pointerEvents="none">&nbsp;</FormHelperText>;
    }

    if (
      (
        errors as unknown as Record<
          typeof passwordConfirmationKey,
          { type: string } | null
        >
      )[passwordConfirmationKey]?.type === 'matchesPassword'
    ) {
      return (
        <FormErrorMessage>
          {t(
            'formValidation.passwordConfirmationNotMatching',
            'Password confirmation does not match',
          )}
        </FormErrorMessage>
      );
    }

    return (
      errors[passwordConfirmationKey] && (
        <FormErrorMessage>
          {errors[passwordConfirmationKey]?.message as string}
        </FormErrorMessage>
      )
    );
  };

  return (
    <FormControl isInvalid={Boolean(errors.passwordConfirmation)}>
      <FormLabel htmlFor={passwordConfirmationKey}>
        {t('formlabel.confirmPassword', 'Confirm Password')}
      </FormLabel>
      <Input
        id={passwordConfirmationKey}
        type="password"
        borderRadius="full"
        autoComplete="new-password"
        placeholder={t('placeholder.confirmPassword', 'Confirm password')}
        {...register(passwordConfirmationKey, {
          required: {
            value: true,
            message: t('formvalidation.required', 'This field is required'),
          },
          validate: {
            matchesPassword: (value) => value === password,
          },
        })}
        {...inputProps}
      />
      {renderPasswordHelperText()}
    </FormControl>
  );
};
