import { Box, Flex, StackProps, Text, VStack } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { passwordStrength } from 'check-password-strength';

import { useTranslation } from '../i18n';

export type PasswordStrengthIndicatorProps = {
  password: string;
} & StackProps;

export const PasswordStrengthIndicator: FC<PasswordStrengthIndicatorProps> = (
  props,
) => {
  const { password, ...stackProps } = props;
  const { value } = passwordStrength(password);

  const { t } = useTranslation();

  const totalSegments = 4;

  const configs: Record<
    string,
    {
      color: string;
      label: string;
      segmentCount: number;
    }
  > = {
    'Too weak': {
      color: '#CD1719',
      label: t('input.passwordStrengthIndicator.weak', 'Weak'),
      segmentCount: 1,
    },
    Weak: {
      color: '#EBC84D',
      label: t('input.passwordStrengthIndicator.ok', 'OK'),
      segmentCount: 2,
    },
    Medium: {
      color: '#70C448',
      label: t('input.passwordStrengthIndicator.good', 'Good'),
      segmentCount: 3,
    },
    Strong: {
      color: '#398316',
      label: t('input.passwordStrengthIndicator.great', 'Great!'),
      segmentCount: 4,
    },
  };

  const config = configs[value]!;

  const items = useMemo(
    () =>
      Array.from({ length: totalSegments }).map((_, i) => {
        return (
          <Box
            key={i}
            bg={i < config.segmentCount ? config.color : 'gray'}
            flex={1}
          />
        );
      }),
    [config.color, config.segmentCount],
  );

  return (
    <VStack alignItems="stretch" {...stackProps}>
      <Flex height={1} gap={1}>
        {items}
      </Flex>
      <Text textAlign="end">{config.label}</Text>
    </VStack>
  );
};
