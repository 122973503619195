import { Box, VStack, Flex, useToast } from '@chakra-ui/react';
import { FC, useEffect, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { DevTool } from '@hookform/devtools';
import { AxiosError } from 'axios';
import { isEmpty } from 'ramda';

import { Button } from '../../ui-components/Button';
import { Trans, useTranslation } from '../../i18n';
import { NetworkErrorAlert } from '../../ui-components/NetworkErrorAlert';
import { ApiErrorResponse } from '../../network/types';
import { useResendActivationTokenMutation } from '../RegistrationVerifyEmailPage';

import { UserRegistrationFormValues } from './types';
import { useRegisterUserMutation } from './useRegisterUserMutation';
import { RegistrationFirstNameField } from './RegistrationFirstNameField';
import { RegistrationEmailField } from './RegistrationEmailField';
import { RegistrationPasswordField } from './RegistrationPasswordField';
import { RegistrationLastNameField } from './RegistrationLastNameField';

export type RegistrationFormProps = {};

export const RegistrationForm: FC<RegistrationFormProps> = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const nameInputRef = useRef<HTMLInputElement>(null!);

  const registerUserMutation = useRegisterUserMutation({
    onSuccess: (_, variables) => {
      navigate(`/register/verify-email?email=${variables.email}`);
    },
    onError: () => {
      requestAnimationFrame(() => nameInputRef.current?.focus());
    },
  });

  const toast = useToast();

  const resendActivationTokenMutation = useResendActivationTokenMutation({
    onSuccess: () => {
      toast({
        title: t(
          'notification.registrationVerifyEmail.success',
          'Activation email sent successfully, check your inbox',
        ),
        status: 'success',
        isClosable: true,
      });
    },
    onError: (error) => {
      if (
        error.response?.data?.type ===
        'urn:problem-type:external-activation-not-allowed'
      ) {
        toast({
          title: t(
            'notification.externalActivationNotAllowed',
            '[1002]Forbidden operation. Please contact support!',
          ),
          status: 'error',
          isClosable: true,
        });
      } else {
        toast({
          title:
            t('notification.generalError', 'Something went wrong, try again…') +
            `\ntraceId: ${error.response?.data?.sentryTraceId}__${error.response?.data?.elkTraceId}`,
          status: 'error',
          isClosable: true,
        });
      }
    },
  });

  const formMethods = useForm<UserRegistrationFormValues>(
    process.env.NODE_ENV === 'development'
      ? {
          defaultValues: {
            email: 'example@gmail.com',
            firstName: 'Krzysztof',
            lastName: 'Suchodolski',
            password: 'asdfasdfA9;',
            rememberMe: true,
          },
        }
      : {},
  );
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
    reset,
    trigger,
    watch,
  } = formMethods;
  i18n.on('languageChanged', () => {
    reset();
  });
  const { firstName, lastName, email, password } = watch();
  useEffect(() => {
    if (trigger && isSubmitted) trigger();
  }, [t, trigger, firstName, lastName, email, password, isSubmitted]);

  const onSubmit = async (values: UserRegistrationFormValues) => {
    return registerUserMutation.mutateAsync(values);
  };

  const renderUserRegistrationError = () => {
    if (!registerUserMutation.isError) {
      return null;
    }

    const errorResponse = (
      registerUserMutation.error as AxiosError<ApiErrorResponse>
    )?.response;

    if (
      errorResponse?.data?.type === 'urn:problem-type:user:active-exists' ||
      errorResponse?.data?.type ===
        'urn:problem-type:user:onboarding-required-exists'
    ) {
      return (
        <NetworkErrorAlert networkError={registerUserMutation.error}>
          {t(
            'notification.account.existsByEmail',
            'Account with specified email already exists',
          )}
        </NetworkErrorAlert>
      );
    }

    if (
      errorResponse?.data?.type ===
      'urn:problem-type:user:email-verification-required-exists'
    ) {
      return (
        <NetworkErrorAlert networkError={registerUserMutation.error}>
          <Trans i18nKey="notification.account.activationRequired">
            Account associated with specified email address already exists and
            has not completed the registration process (email verification is
            required). If you did not receive verification email click
            <Button
              as={Link}
              variant="link"
              onClick={() => resendActivationTokenMutation.mutate({ email })}
            >
              here
            </Button>{' '}
            to receive new verification email and use it to complete
            registration process
          </Trans>
        </NetworkErrorAlert>
      );
    }

    return (
      <NetworkErrorAlert networkError={registerUserMutation.error}>
        {t('notification.generalError', 'Something went wrong, try again…')}
      </NetworkErrorAlert>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <DevTool control={control} />
      <Box as="form" onSubmit={handleSubmit(onSubmit)} width="full">
        <VStack alignItems="stretch" spacing={6}>
          <Flex
            as="fieldset"
            direction="column"
            disabled={registerUserMutation.isLoading}
          >
            <RegistrationFirstNameField inputRef={nameInputRef} autoFocus />
            <RegistrationLastNameField />
            <RegistrationEmailField />
            <RegistrationPasswordField />
          </Flex>
          {renderUserRegistrationError()}
          <VStack alignItems="stretch">
            <Button
              type="submit"
              width="full"
              isLoading={registerUserMutation.isLoading}
              disabled={!isEmpty(errors)}
            >
              {t('button.registration', 'CREATE ACCOUNT')}
            </Button>
          </VStack>
        </VStack>
      </Box>
    </FormProvider>
  );
};
