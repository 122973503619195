import {
  Box,
  Wrap,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import { FC, FormEventHandler } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Button } from '../../ui-components/Button';
import { SelectBase } from '../../ui-components/multiSelect/SelectBase';
import { CompanyInformation } from '../OnboardingPage';
import { useTranslation } from '../../i18n';
import { NetworkErrorAlert } from '../../ui-components/NetworkErrorAlert';
import {
  addressFieldMaxLength,
  apartmentFieldMaxLength,
  cityFieldMaxLength,
  companyNameFieldMaxLength,
  companyVatFieldMaxLength,
  postcodeFieldMaxLength,
} from '../../mathHelpers';
import { useFormatDictionary } from '../../typeHelpers';
import { useDictionaryQuery } from '../../hooks/useDictionaryQuery';

import { useUserOnboardingMutation } from './useUserOnboardingMutation';

export type OnboardingCompanyInformationStepProps = {
  onCompleted: () => void;
  onboardingMutation: ReturnType<typeof useUserOnboardingMutation>;
  onBack: () => void;
};

export const OnboardingCompanyInformationStep: FC<
  OnboardingCompanyInformationStepProps
> = (props) => {
  const { onCompleted, onboardingMutation } = props;
  const { t, i18n } = useTranslation();
  const formMethods = useFormContext<CompanyInformation>();
  const countriesDictionary = useDictionaryQuery('COUNTRY');
  const countriesData = useFormatDictionary(countriesDictionary.data);
  const countryOptions = countriesData.map(({ code, value }) => ({
    value: code,
    label: value,
  }));

  const {
    register,
    control,
    formState: { errors },
    reset,
  } = formMethods;

  i18n.on('languageChanged', () => {
    reset();
  });

  // TODO: create a `Select` component
  // DUP: 1
  const renderCountryField = () => {
    const options = countryOptions;

    return (
      <Controller
        control={control}
        name="country"
        rules={{
          required: {
            value: true,
            message: t('formvalidation.required', 'This field is required'),
          },
        }}
        render={(args) => {
          const {
            field,
            fieldState: { error },
          } = args;

          const { onChange, value, ref, name, ...fieldProps } = field;

          return (
            <FormControl isInvalid={Boolean(error)}>
              <FormLabel>{t('formlabel.company.country', 'Country')}</FormLabel>

              <SelectBase
                autoFocus
                borderRadius="xl"
                borderColor="gray.500"
                options={options}
                autoComplete="country"
                placeholder={t('placeholder.company.country', 'Enter country')}
                {...fieldProps}
                styles={{ container: () => ({ width: '100%' }) }}
                onChange={({ value }: { value: string }) => {
                  onChange({ target: { name, value } });
                }}
                value={options.find(({ value: v }) => value === v)}
              />

              {error?.message && (
                <FormErrorMessage>{error.message}</FormErrorMessage>
              )}
            </FormControl>
          );
        }}
      />
    );
  };

  const renderNameField = () => (
    <FormControl isInvalid={Boolean(errors.companyName)}>
      <FormLabel>{t('formlabel.company.name', 'Company name')}</FormLabel>

      <Input
        autoComplete="organization"
        {...register('companyName', {
          required: t('formvalidation.required', 'This field is required'),
          maxLength: {
            value: companyNameFieldMaxLength,
            message: t('formvalidation.maxLength', {
              defaultValue: 'Maximum length should be {{maxLength}}',
              maxLength: companyNameFieldMaxLength,
            }),
          },
        })}
        placeholder={t('placeholder.company.name', 'Enter company name')}
      />
      {errors.companyName?.message && (
        <FormErrorMessage>{errors.companyName.message}</FormErrorMessage>
      )}
    </FormControl>
  );

  const renderNumberField = () => (
    <FormControl isInvalid={Boolean(errors.companyVatNumber)}>
      <FormLabel>{t('formlabel.company.vat', 'Company VAT number')}</FormLabel>

      <Input
        {...register('companyVatNumber', {
          required: t('formvalidation.required', 'This field is required'),
          maxLength: {
            value: companyVatFieldMaxLength,
            message: t('formvalidation.maxLength', {
              defaultValue: 'Maximum length should be {{maxLength}}',
              maxLength: companyVatFieldMaxLength,
            }),
          },
        })}
        placeholder={t('placeholder.company.vat', 'Enter VAT number')}
      />
      {errors.companyVatNumber && (
        <FormErrorMessage>{errors.companyVatNumber?.message}</FormErrorMessage>
      )}
    </FormControl>
  );

  const renderAddressField = () => (
    <FormControl isInvalid={Boolean(errors.address)}>
      <FormLabel>{t('formlabel.company.address', 'Address')}</FormLabel>

      <Input
        autoComplete="address-line1"
        {...register('address', {
          required: t('formvalidation.required', 'This field is required'),
          maxLength: {
            value: addressFieldMaxLength,
            message: t('formvalidation.maxLength', {
              defaultValue: 'Maximum length should be {{maxLength}}',
              maxLength: addressFieldMaxLength,
            }),
          },
        })}
        placeholder={t('placeholder.company.address', 'Enter address')}
      />
      {errors.address && (
        <FormErrorMessage>{errors.address?.message}</FormErrorMessage>
      )}
    </FormControl>
  );

  const renderApartmentNumberField = () => (
    <FormControl isInvalid={Boolean(errors.apartmentNumber)}>
      <FormLabel>
        {t('formlabel.company.apartmentNumber', 'Apartment number')}
      </FormLabel>

      <Input
        autoComplete="address-line2"
        {...register('apartmentNumber', {
          maxLength: {
            value: apartmentFieldMaxLength,
            message: t('formvalidation.maxLength', {
              defaultValue: 'Maximum length should be {{maxLength}}',
              maxLength: apartmentFieldMaxLength,
            }),
          },
        })}
        placeholder={t(
          'placeholder.company.apartmentNumber',
          'Enter apartment number',
        )}
      />
      {errors.apartmentNumber && (
        <FormErrorMessage>{errors.apartmentNumber?.message}</FormErrorMessage>
      )}
    </FormControl>
  );

  const renderCityField = () => (
    <FormControl isInvalid={Boolean(errors.city)}>
      <FormLabel>{t('formlabel.company.city', 'City')}</FormLabel>

      <Input
        {...register('city', {
          required: t('formvalidation.required', 'This field is required'),
          maxLength: {
            value: cityFieldMaxLength,
            message: t('formvalidation.maxLength', {
              defaultValue: 'Maximum length should be {{maxLength}}',
              maxLength: cityFieldMaxLength,
            }),
          },
        })}
        placeholder={t('placeholder.company.city', 'Enter city')}
      />
      {errors.city && (
        <FormErrorMessage>{errors.city?.message}</FormErrorMessage>
      )}
    </FormControl>
  );

  const renderPostcodeField = () => (
    <FormControl isInvalid={Boolean(errors.postcode)}>
      <FormLabel>{t('formlabel.company.postcode', 'Postcode')}</FormLabel>

      <Input
        autoComplete="postal-code"
        {...register('postcode', {
          required: t('formvalidation.required', 'This field is required'),
          maxLength: {
            value: postcodeFieldMaxLength,
            message: t('formvalidation.maxLength', {
              defaultValue: 'Maximum length should be {{maxLength}}',
              maxLength: postcodeFieldMaxLength,
            }),
          },
        })}
        placeholder={t('placeholder.company.postcode', 'Enter postcode')}
      />
      {errors.postcode && (
        <FormErrorMessage>{errors.postcode?.message}</FormErrorMessage>
      )}
    </FormControl>
  );

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    onCompleted();
  };

  const renderMutationError = () => {
    if (!onboardingMutation.isError) {
      return null;
    }

    return (
      <NetworkErrorAlert networkError={onboardingMutation.error}>
        {t('notification.generalError', 'Something went wrong, try again…')}
      </NetworkErrorAlert>
    );
  };

  return (
    <Box paddingY={5} height="full" overflowY="auto">
      <Stack as="form" onSubmit={onSubmit} spacing={10}>
        <Stack spacing={5}>
          <Stack>
            {renderCountryField()}

            {renderNameField()}
            {renderNumberField()}
          </Stack>

          <SimpleGrid columns={[1, null, 2, null, null]} gap={3}>
            {renderAddressField()}
            {renderApartmentNumberField()}

            {renderCityField()}
            {renderPostcodeField()}
          </SimpleGrid>
        </Stack>

        {renderMutationError()}

        <Wrap width="full" minWidth="600px">
          <Button variant="outline" onClick={props.onBack} flex={1}>
            {t('button.back', 'BACK')}
          </Button>

          <Button
            type="submit"
            width="full"
            flex={1}
            isLoading={onboardingMutation.isLoading}
          >
            {t('button.continue', 'CONTINUE')}
          </Button>
        </Wrap>
      </Stack>
    </Box>
  );
};
