import { mediaBoxEnv } from './mediaboxEnv';

export const localAppConfig = {
  eppBackendServiceBaseUrl: '/epp-backend',
  fileServiceBaseUrl: '/files-service',
  campaignFilesServingServiceBaseUrl: '/files',
  dictionaryServiceBaseUrl: '/dictionary-service',
  activityServiceBaseUrl: '/activity-service',
  refreshAuthTokenIntervalInMillis: 1781,
  myWorldPspBaseUrl:
    'https://test-partner.myworld.com/projects-2023-mediabox-creditspurchase/it/purchase',
  accountServiceBaseUrl: '/account',
  sentryUrl: '',
  skipRecaptcha: true,
};

export type InitAppConfig = {
  eppBackendServiceBaseUrl: string;
};

type ConfigQueryData = {
  fileServiceBaseUrl: string;
  dictionaryServiceBaseUrl: string;
  activityServiceBaseUrl: string;
  refreshAuthTokenIntervalInMillis: number;
  campaignFilesServingServiceBaseUrl: string;
  myWorldPspBaseUrl: string;
  accountServiceBaseUrl: string;
  sentryUrl: string;
  skipRecaptcha: boolean;
};

const eppBackendURL = () => {
  const nonLocalUrl = 'https://epp-backend.360mediabox.site'; //this value is replaced each time when image is deployed
  if ('react_app_epp_backend_url'.toUpperCase() === nonLocalUrl) {
    throw Error(
      `EPP Backend URL was not runtime replaced with a meaningful value`,
    );
  }
  if (mediaBoxEnv === 'local') {
    return localAppConfig.eppBackendServiceBaseUrl;
  } else {
    return nonLocalUrl;
  }
};

export type AppConfig = InitAppConfig & ConfigQueryData;

if (!['test', 'development'].includes(process.env.NODE_ENV)) {
  const initAppConfig: InitAppConfig = {
    eppBackendServiceBaseUrl: eppBackendURL(),
  };
  const request = new XMLHttpRequest();
  request.open(
    'GET',
    `${initAppConfig.eppBackendServiceBaseUrl}/api/configurations/current`,
    false,
  ); // `false` makes the request synchronous
  request.send(null);

  if (request.status === 200) {
    const responseJSON = JSON.parse(request.response);
    window.appConfig = {
      ...initAppConfig,
      ...responseJSON,
    };
  }
}

export const appConfig = window.appConfig || localAppConfig;
