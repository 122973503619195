import { FC } from 'react';
import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Image,
} from '@chakra-ui/react';

import { FlagIcon } from '../icons/FlagIcon';
import ItalyFlag from '../assets/italy-flag-icon.png';
import SloveniaFlag from '../assets/slovenia-flag-icon.png';
import EnglishFlag from '../assets/united-kingdom-flag-icon.png';
import GermanFlag from '../assets/german-flag-icon.png';
import PortugalFlag from '../assets/portugal-flag-icon.png';
import MacedonianFlag from '../assets/macedonian-flag-icon.png';
import KosovoFlag from '../assets/kosovo-flag-icon.png';
import LithuanianFlag from '../assets/lithuanian-flag-icon.png';
import SerbianFlag from '../assets/serbia-flag-icon.jpeg';
import CroatianFlag from '../assets/croatia-flag-icon.png';
import {
  useAppLanguage,
  AppLanguage,
  useTranslation,
  APP_LANGUAGES,
} from '../i18n';
import { mediaBoxEnv } from '../mediaboxEnv';

export type LanguageFlagsProps = {};

type FlagObject = {
  flag: string;
  languageName: string;
  hideOnProduction?: boolean;
};

export const useFlags: () => Record<AppLanguage, FlagObject> = () => {
  const { t } = useTranslation();
  const flags: Record<AppLanguage, FlagObject> = {
    IT: {
      flag: ItalyFlag,
      languageName: t('language.italian', 'Italiano'),
    },
    SL: {
      flag: SloveniaFlag,
      languageName: t('language.slovenian', 'Slovenščina'),
    },
    EN: {
      flag: EnglishFlag,
      languageName: t('language.english', 'English'),
    },
    DE: {
      flag: GermanFlag,
      languageName: t('language.german', 'Deutsch'),
    },
    PT: {
      flag: PortugalFlag,
      languageName: t('language.portugal', 'Portugal'),
    },
    MK: {
      flag: MacedonianFlag,
      languageName: t('language.macedonian', 'македонски'),
    },
    SQ: {
      flag: KosovoFlag,
      languageName: t('language.kosovo', 'Kosovo'),
    },
    LT: {
      flag: LithuanianFlag,
      languageName: t('language.lithuanian', 'Lithuanian'),
    },
    SR: {
      flag: SerbianFlag,
      languageName: t('language.serbian', 'Serbia'),
    },
    HR: {
      flag: CroatianFlag,
      languageName: t('language.croatian', 'Croatia'),
    },
  };

  return flags;
};

export const LanguageFlags: FC<LanguageFlagsProps> = () => {
  const [appLanguage, setAppLanguage] = useAppLanguage();

  const flags = useFlags();

  return (
    <Flex position="fixed" right="20px" top="20px">
      <Menu>
        <MenuButton
          width="59px"
          padding={1.5}
          backgroundColor="transparent"
          boxShadow="sm"
          borderRadius="md"
          border="1px solid"
          borderColor="gray.300"
        >
          <Image
            margin="auto"
            width="35px"
            height="20px"
            src={flags[appLanguage].flag}
          />
        </MenuButton>
        <Portal>
          <MenuList minWidth="50px" zIndex="999">
            {APP_LANGUAGES.map((flagLanguage: AppLanguage) => {
              if (appLanguage === flagLanguage) return null;

              const { flag, hideOnProduction, languageName } =
                flags[flagLanguage];

              return (
                <MenuItem
                  key={`language-flag-item-${flagLanguage}`}
                  gap="5px"
                  onClick={() => setAppLanguage(flagLanguage)}
                  display={
                    mediaBoxEnv === 'prod' && hideOnProduction ? 'none' : 'flex'
                  }
                >
                  <FlagIcon src={flag} alt={languageName} />
                  {languageName}
                </MenuItem>
              );
            })}
          </MenuList>
        </Portal>
      </Menu>
    </Flex>
  );
};
