import { FC, MutableRefObject } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useFormContext } from 'react-hook-form';
import { useBoolean } from 'react-use';

import { useTranslation } from '../../i18n';

import { UserLoginFormValues } from './types';

export type LoginPasswordFieldProps = InputProps & {
  inputRef?: MutableRefObject<HTMLInputElement>;
};

export const LoginPasswordField: FC<LoginPasswordFieldProps> = (props) => {
  const { inputRef, ...inputProps } = props;
  const { t, i18n } = useTranslation();
  const {
    register,
    formState: { errors },
    reset,
  } = useFormContext<UserLoginFormValues>();
  i18n.on('languageChanged', () => {
    reset();
  });
  const passwordProps = register('password', {
    required: {
      value: true,
      message: t('formvalidation.required', 'This field is required'),
    },
  });
  const [showPassword, toggleShowPassword] = useBoolean(false);
  return (
    <FormControl isInvalid={Boolean(errors.password)}>
      <FormLabel htmlFor="password">
        {t('formlabel.password', 'Password')}
      </FormLabel>

      <InputGroup>
        <Input
          id="password"
          type={showPassword ? 'text' : 'password'}
          borderRadius="full"
          autoComplete="new-password"
          placeholder={t('placeholder.password', 'Enter your password here')}
          {...passwordProps}
          ref={(element) => {
            if (inputRef && element) {
              inputRef.current = element;
            }

            passwordProps.ref(element);
          }}
          {...inputProps}
        />
        <InputRightElement
          children={
            showPassword ? (
              <IconButton
                variant="unstyled"
                aria-label="Hide password"
                onClick={toggleShowPassword}
                icon={<ViewOffIcon />}
              />
            ) : (
              <IconButton
                aria-label="Show password"
                variant="unstyled"
                onClick={toggleShowPassword}
                icon={<ViewIcon />}
              />
            )
          }
        />
      </InputGroup>
      {errors.password && (
        <FormErrorMessage>{errors.password.message}</FormErrorMessage>
      )}
    </FormControl>
  );
};
