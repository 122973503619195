import { i18n } from './i18n';

export const trimmedRequired = (value: unknown) => {
  const validateRequired = () => {
    if (typeof value === 'number') {
      return !Number.isNaN(value);
    }

    if (typeof value === 'string') {
      return Boolean(value.trim());
    }

    return Boolean(value);
  };

  return (
    validateRequired() ||
    i18n.t<string>('formvalidation.required', 'This field is required')
  );
};

// keep this "lazy" to let react initialize translations first
export const firstNamePattern = () => ({
  value:
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,255}$/i,
  message: i18n.t<string>(
    'formvalidation.format',
    'Wrong format. Please enter correct first name',
    {
      type: i18n
        .t<string>('formlabel.firstName', 'first name')
        .toLocaleLowerCase(),
    },
  ),
});

export const lastNamePattern = () => ({
  value:
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{3,255}$/i,
  message: i18n.t<string>(
    'formvalidation.format',
    'Wrong format. Please enter correct {{type}}',
    {
      type: i18n
        .t<string>('formlabel.lastName', 'last name')
        .toLocaleLowerCase(),
    },
  ),
});

export const phoneNumberPattern = () => ({
  value: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
  message: i18n.t<string>(
    'formvalidation.format',
    'Wrong format. Please enter correct {{type}}',
    {
      type: i18n
        .t<string>('formlabel.phoneNumber', 'phone number')
        .toLocaleLowerCase(),
    },
  ),
});
