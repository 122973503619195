import { useCallback } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import HttpBackend from 'i18next-http-backend';

import { TupleValues } from './types';
import { mediaBoxEnv } from './mediaboxEnv';
import { appConfig } from './appConfig';

export const APP_LANGUAGES = [
  'DE',
  'EN',
  'HR',
  'IT',
  'SQ',
  'LT',
  'MK',
  'PT',
  'SL',
  'SR',
] as const;

export type AppLanguage = TupleValues<typeof APP_LANGUAGES>;

export const defaultAppLanguage: AppLanguage = 'EN';

export const initI18NForApp = () => {
  return i18n
    .use(LanguageDetector)
    .use(ChainedBackend)
    .use(initReactI18next) // passes i18n down to react-i18next

    .init({
      supportedLngs: APP_LANGUAGES,
      fallbackLng: defaultAppLanguage,

      interpolation: {
        escapeValue: false, // react already saves from xss
      },
      debug: mediaBoxEnv !== 'prod',

      detection: {
        // order and from where user language should be detected
        order: ['querystring', 'localStorage', 'navigator'],

        // keys or params to lookup language from
        lookupQuerystring: 'lang',
        lookupLocalStorage: 'i18nextLng',
      },

      backend: {
        backends: [
          resourcesToBackend((language, namespace, callback) => {
            if (!['test', 'development'].includes(process.env.NODE_ENV)) {
              const request = new XMLHttpRequest();
              request.open(
                'GET',
                `${appConfig.dictionaryServiceBaseUrl}/api/dictionaries/PARTNER_PANEL`,
                false,
              );
              request.setRequestHeader('Accept-Language', language);
              request.send(null);

              const responseJSON = JSON.parse(request.response);

              if (request.status === 200) {
                let translations: any = {};
                const entries = responseJSON.entriesByLanguage[language];
                for (const { code, value } of entries) {
                  translations[code] = value;
                }
                callback(null, translations);
              } else {
                callback(responseJSON, null);
              }
            } else {
              import(`./locales/EN/translation.json`)
                .then(({ default: resources }) => {
                  callback(null, resources);
                })
                .catch((error) => {
                  callback(error, null);
                });
            }
          }),
          HttpBackend,
        ],
      } as any,
    });
};

export const useAppLanguage = () => {
  const appLanguage = i18n.language as AppLanguage;

  const setAppLanguage = useCallback((lng: AppLanguage) => {
    localStorage.setItem('isDefaultLanguage', 'false');
    return i18n.changeLanguage(lng);
  }, []);

  return [appLanguage, setAppLanguage] as const;
};

export * from 'react-i18next';

export { i18n };
