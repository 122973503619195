import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
} from '@chakra-ui/react';
import { FC, MutableRefObject } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from '../../i18n';
import { lastNamePattern, trimmedRequired } from '../../validators';

import { UserRegistrationFormValues } from './types';

export type RegistrationLastNameFieldProps = {
  inputRef?: MutableRefObject<HTMLInputElement>;
} & InputProps;

export const RegistrationLastNameField: FC<RegistrationLastNameFieldProps> = (
  props,
) => {
  const { inputRef, ...inputProps } = props;

  const { t, i18n } = useTranslation();
  const {
    register,
    formState: { errors },
    reset,
  } = useFormContext<UserRegistrationFormValues>();
  i18n.on('languageChanged', () => {
    reset();
  });
  const nameInputProps = register('lastName', {
    pattern: lastNamePattern(),
    validate: {
      trimmedRequired,
    },
  });

  return (
    <FormControl isInvalid={Boolean(errors.lastName)}>
      <FormLabel htmlFor="lastName">
        {t('formlabel.lastName', 'Last Name')}
      </FormLabel>
      <Input
        {...inputProps}
        id="lastName"
        type="text"
        borderRadius="full"
        autoComplete="family-name"
        placeholder={t('placeholder.lastName', 'Mus')}
        {...nameInputProps}
        ref={(e) => {
          if (!e) {
            return;
          }
          nameInputProps.ref(e);
          if (inputRef) {
            inputRef.current = e!;
          }
        }}
      />
      {errors.lastName && (
        <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>
      )}
    </FormControl>
  );
};
