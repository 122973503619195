import { Heading, Text, Button, Stack, AlertIcon } from '@chakra-ui/react';
import { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMount } from 'react-use';

import { useTranslation } from '../i18n';
import { HeroPage } from '../ui-components/HeroPage';
import { Alert } from '../ui-components/Alert';
import { LinkButton } from '../ui-components/LinkButton';
import { FillingLoader } from '../ui-components/FillingLoader';
import { NetworkErrorAlert } from '../ui-components/NetworkErrorAlert';

import { useActivateUserMutation } from './registrationEmailVerifiedPage/useActivateUserMutation';

export type RegistrationEmailVerifiedPageProps = {};

export const RegistrationEmailVerifiedPage: FC<
  RegistrationEmailVerifiedPageProps
> = (props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const activationToken = searchParams.get('activationToken');

  const activateUserMutation = useActivateUserMutation();

  useMount(() => {
    if (activationToken) {
      activateUserMutation.mutate({ activationToken });
    }
  });

  const renderMissingKeyInfo = () => {
    return (
      <Stack spacing={8}>
        <Alert status="error">
          <AlertIcon />
          {t('notification.missingCredentials', 'Missing credentials')}
        </Alert>

        <Button
          onClick={() => {
            navigate('/');
          }}
        >
          {t('button.returnHome', 'Return to Home')}
        </Button>
      </Stack>
    );
  };

  const renderActivationState = () => {
    if (activateUserMutation.isLoading || activateUserMutation.isIdle) {
      return (
        <FillingLoader
          message={t('notification.loading', 'Loading, please wait…')}
        />
      );
    }

    if (activateUserMutation.isError) {
      return (
        <Stack spacing={8}>
          <NetworkErrorAlert networkError={activateUserMutation.error}>
            {t('notification.generalError', 'Something went wrong, try again…')}
          </NetworkErrorAlert>

          <Button
            onClick={() => {
              activateUserMutation.mutate({
                activationToken: activationToken!,
              });
            }}
            isLoading={activateUserMutation.isLoading}
          >
            {t('button.retry', 'Retry')}
          </Button>
        </Stack>
      );
    }

    return (
      <Stack textAlign="center" spacing={5}>
        <Stack>
          <Heading>
            {t('registration.emailVerified.title', 'Email verified')}
          </Heading>

          <Text>
            {t(
              'registration.emailVerified.subtitle',
              'Your email has been confirmed, Log In to continue',
            )}
          </Text>
        </Stack>

        <LinkButton to="/login">{t('button.login', 'LOGIN')}</LinkButton>
      </Stack>
    );
  };

  return (
    <HeroPage>
      {activationToken ? renderActivationState() : renderMissingKeyInfo()}
    </HeroPage>
  );
};
