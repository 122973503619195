import { FC, useState } from 'react';
import {
  Box,
  Center,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { FormProvider, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useBoolean } from 'react-use';

import { useTranslation } from '../i18n';
import { BrandLogo } from '../ui-components/BrandLogo';
import { useDictionaryQuery } from '../hooks/useDictionaryQuery';
import { FullScreenLoader } from '../ui-components/FullScreenLoader';
import { Button } from '../ui-components/Button';
import { invertArray } from '../arrayHelpers';
import { NetworkErrorAlert } from '../ui-components/NetworkErrorAlert';
import { LanguageFlags } from '../ui-components/LanguageFlags';
import { useLoggedInUser } from '../useAuthUser';
import { Footer } from '../ui-components/Footer';

import { OnboardingSetUpStep } from './onboardingPage/OnboardingSetUpStep';
import { OnboardingSuccessStep } from './onboardingPage/OnboardingSuccessStep';
import { OnboardingCompanyInformationStep } from './onboardingPage/OnboardingCompanyInformationStep';
import {
  CampaignTargetType,
  CompanySize,
  RoleType,
  useUserOnboardingMutation,
} from './onboardingPage/useUserOnboardingMutation';

export type OnboardingPageProps = {};

const StepTab = (props: TabProps & { complete?: boolean; label: string }) => {
  const { complete, label, ...tabProps } = props;

  return (
    <Tab {...tabProps}>
      <Text fontWeight="semibold" fontSize="md" color="gray.600">
        {label}
      </Text>

      {complete && (
        <>
          &nbsp;
          <CheckIcon />
        </>
      )}
    </Tab>
  );
};

export type CompanyInformation = {
  companyName: string;
  companyVatNumber: string;
  country: string;
  city: string;
  address: string;
  apartmentNumber: string;
  postcode: string;
};

export type OnboardingFormStep1Fields = {
  companySize: CompanySize;
};
export type OnboardingFormStep2Fields = {
  userRole: RoleType;
  userRoleAdditionalDetails: string | null;
};
export type OnboardingFormStep3Fields = {
  campaignTarget: CampaignTargetType;
  campaignTargetAdditionalDetails: string | null;
};

export type OnboardingFormFields = OnboardingFormStep1Fields &
  OnboardingFormStep2Fields &
  OnboardingFormStep3Fields &
  CompanyInformation;

const tabOrder = ['signup', 'setup', 'companyInfo', 'success'] as const;

const tabIndices = invertArray(tabOrder);

export const OnboardingPage: FC<OnboardingPageProps> = (props) => {
  const { t } = useTranslation();

  const countriesDictionary = useDictionaryQuery('COUNTRY');

  const [tabIndex, setTabIndex] = useState(tabIndices.setup);
  const [isSetLastStepForSetUpStep, setIsSetLastStepForSetUpStep] =
    useBoolean(false);

  const userOnboardingMutation = useUserOnboardingMutation({
    onSuccess: () => {
      setTabIndex(tabIndices.success);
    },
  });

  const { additional } = useLoggedInUser();

  const formMethods = useForm<OnboardingFormFields>(
    process.env.NODE_ENV === 'development'
      ? {
          defaultValues: {
            address: 'Random Street 16',
            apartmentNumber: '15',
            campaignTarget: 'AGENCY_CLIENTS',
            campaignTargetAdditionalDetails:
              'campaign target additional details',
            city: 'Random City',
            companyName: 'Company name',
            companySize: 'MEDIUM',
            companyVatNumber: '[company vat number]',
            country: 'PL',
            postcode: '34534',
            userRole: 'DESIGNER',
            userRoleAdditionalDetails: 'user role additional details',
          },
        }
      : {
          defaultValues: {
            address: additional?.company?.address?.address || '',
            apartmentNumber:
              additional?.company?.address?.apartmentNumber || '',
            campaignTarget:
              additional?.campaignsTarget?.type || 'EMPLOYER_COMPANY',
            campaignTargetAdditionalDetails:
              additional?.campaignsTarget?.detailsForOther || '',
            city: additional?.company?.address?.city || '',
            companyName: additional?.company?.name || '',
            companySize: additional?.company?.size || 'MEDIUM',
            companyVatNumber: additional?.company?.vat || '',
            country: additional?.company?.address?.country || '',
            postcode: additional?.company?.address?.postcode || '',
            userRole: additional?.company?.role?.type || 'MARKETING_MANAGER',
            userRoleAdditionalDetails:
              additional?.company?.role?.detailsForOther || '',
          },
        },
  );

  if (countriesDictionary.isLoading) {
    return <FullScreenLoader />;
  }

  if (countriesDictionary.isError) {
    return (
      <Center minHeight="100vh" width="full">
        <Stack spacing={8}>
          <NetworkErrorAlert networkError={countriesDictionary.error}>
            {t('notification.generalError', 'Something went wrong, try again…')}
          </NetworkErrorAlert>

          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('button.reloadThePage', 'Reload the page')}
          </Button>
        </Stack>
      </Center>
    );
  }

  const { handleSubmit, control } = formMethods;

  const onSubmit = handleSubmit((submitValues) => {
    const {
      address,
      apartmentNumber,
      campaignTarget,
      campaignTargetAdditionalDetails,
      city,
      companyName,
      companySize,
      companyVatNumber,
      country,
      postcode,
      userRole,
      userRoleAdditionalDetails,
    } = submitValues;

    userOnboardingMutation.mutate({
      campaignsTarget: {
        type: campaignTarget,
        detailsForOther: campaignTargetAdditionalDetails || null,
      },
      company: {
        name: companyName,
        size: companySize,
        vat: companyVatNumber,
        role: {
          type: userRole,
          detailsForOther: userRoleAdditionalDetails || null,
        },
        address: {
          country,
          address,
          apartmentNumber,
          city,
          postcode,
        },
      },
    });
  });

  return (
    <FormProvider {...formMethods}>
      <DevTool control={control} />
      <LanguageFlags />
      <VStack>
        <VStack minWidth={['70%', null, '0%']} minHeight="100vh">
          <Center flex={1}>
            <BrandLogo />
          </Center>
          <Box width="full">
            <Tabs
              isFitted
              width="full"
              index={tabIndex}
              onChange={(newTabIndex) => {
                if (tabIndex === tabIndices.success) {
                  return;
                }
                if (newTabIndex === tabIndices.signup) {
                  return;
                }
                // allow only navigation back by tab click
                if (newTabIndex >= tabIndex) {
                  return;
                }
                setIsSetLastStepForSetUpStep(false);
                setTabIndex(newTabIndex);
              }}
            >
              <TabList mb="1em">
                <StepTab
                  complete={tabIndex > tabIndices.signup}
                  label={t('onboarding.step.signUp', 'Sign up')}
                />
                <StepTab
                  complete={tabIndex > tabIndices.setup}
                  label={t('onboarding.step.setUp', 'Set up')}
                />
                <StepTab
                  complete={tabIndex > tabIndices.companyInfo}
                  label={t(
                    'onboarding.step.companyInfo',
                    'Company information',
                  )}
                />
              </TabList>
              <TabPanels>
                <TabPanel />
                <TabPanel>
                  {tabIndex === tabIndices.setup && (
                    <OnboardingSetUpStep
                      onCompleted={() => {
                        setIsSetLastStepForSetUpStep(false);
                        setTabIndex(tabIndices.companyInfo);
                      }}
                      setLastStep={isSetLastStepForSetUpStep}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {tabIndex === tabIndices.companyInfo && (
                    <OnboardingCompanyInformationStep
                      onCompleted={onSubmit}
                      onboardingMutation={userOnboardingMutation}
                      onBack={() => {
                        setIsSetLastStepForSetUpStep(true);
                        setTabIndex(tabIndices.setup);
                      }}
                    />
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
            {tabIndex === tabIndices.success && <OnboardingSuccessStep />}
          </Box>
          <Box flex={1} />
          <Footer navPrefix={'/public'} />
        </VStack>
      </VStack>
    </FormProvider>
  );
};
