import { useCallback } from 'react';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import invariant from 'tiny-invariant';
import * as z from 'zod';

import { useApi } from './network/ApiProvider';
import { ApiResponse, AxiosApiError } from './network/types';
import { useAuthState, useIsAuthenticated } from './AuthProvider';
import { useAppLanguage, APP_LANGUAGES } from './i18n';

export const createAuthUserCacheKey = () => ['auth-user'];

const authUserDataResponseSchema = z.object({
  id: z.string().min(1),
  status: z.union([
    z.literal('EMAIL_VERIFICATION_REQUIRED'),
    z.literal('ONBOARDING_REQUIRED'),
    z.literal('ACTIVE'),
  ]),
  info: z.object({
    email: z.string().min(1),
    phoneNumber: z.string().nullable(),
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    notificationsLanguage: z.enum(APP_LANGUAGES),
  }),
  additional: z
    .object({
      campaignsTarget: z
        .object({
          type: z.union([
            z.literal('OWN_BUSINESS'),
            z.literal('EMPLOYER_COMPANY'),
            z.literal('FREELANCER_CLIENTS'),
            z.literal('AGENCY_CLIENTS'),
            z.literal('OTHER'),
          ]),
          detailsForOther: z.string().nullable(),
        })
        .nullable(),
      company: z
        .object({
          name: z.string().min(1).nullable(),
          vat: z.string().min(1).nullable(),
          address: z
            .object({
              country: z.string().min(1).nullable(),
              city: z.string().min(1).nullable(),
              address: z.string().min(1).nullable(),
              apartmentNumber: z.string().nullable(),
              postcode: z.string().min(1).nullable(),
            })
            .nullable(),
          size: z
            .union([
              z.literal('MICRO'),
              z.literal('SMALL'),
              z.literal('MEDIUM'),
              z.literal('BIG'),
            ])
            .nullable(),
          role: z
            .object({
              type: z.union([
                z.literal('MARKETING_MANAGER'),
                z.literal('CEO'),
                z.literal('ENTREPRENEUR'),
                z.literal('DESIGNER'),
                z.literal('OTHER'),
              ]),
              detailsForOther: z.string().nullable(),
            })
            .nullable(),
        })
        .nullable(),
    })
    .nullable(),
});

export type UseAuthUserData = z.infer<typeof authUserDataResponseSchema>;

type QueryResult = ApiResponse<UseAuthUserData>;

export const useAuthUser = (
  opts?: UseQueryOptions<QueryResult, AxiosApiError>,
) => {
  const api = useApi();
  const isAuthenticated = useIsAuthenticated()();

  const queryFn = async ({ signal }: { signal?: AbortSignal }) => {
    const { data } = await api.get('/api/users/me', { signal });
    return authUserDataResponseSchema.parse(data);
  };

  return useQuery<QueryResult, AxiosApiError>(
    createAuthUserCacheKey(),
    queryFn,
    {
      enabled: isAuthenticated,
      staleTime: Infinity,
      ...opts,
    },
  );
};

export const useInvalidateUseAuthUserQuery = () => {
  const queryClient = useQueryClient();
  return useCallback(
    () => queryClient.invalidateQueries(createAuthUserCacheKey()),
    [queryClient],
  );
};

export const useLoggedInUser = () => {
  const { data } = useAuthUser();
  const authState = useAuthState()();
  const [, setAppLanguage] = useAppLanguage();
  const clientCountryLanguage =
    data?.additional?.company?.address?.country?.toLowerCase();
  const isDefaultAppLanguage = localStorage.getItem('isDefaultLanguage');
  if (clientCountryLanguage && isDefaultAppLanguage === 'true') {
    for (let key of APP_LANGUAGES) {
      if (key === clientCountryLanguage) {
        setAppLanguage(key);
      }
    }
  }

  invariant(data && authState, 'logged in user accessed before being loaded');

  return {
    ...data,
    userType: authState.userType,
    permissions: authState.permissions,
  };
};
