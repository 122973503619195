import { Box, Flex, VStack } from '@chakra-ui/react';
import { FC, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DevTool } from '@hookform/devtools';
import ReCAPTCHA from 'react-google-recaptcha';
import { isEmpty } from 'ramda';

import { Button } from '../../ui-components/Button';
import { useTranslation } from '../../i18n';
import { NetworkErrorAlert } from '../../ui-components/NetworkErrorAlert';
import { grecaptchaKey } from '../../grecaptcha';

import { ForgotPasswordEmailField } from './ForgotPasswordEmailField';
import { ForgotPasswordFormValues } from './types';
import { useForgotPasswordMutation } from './useForgotPasswordMutation';

export type ForgotPasswordFormProps = {
  skipRecaptcha: boolean;
};

export const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({
  skipRecaptcha,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const emailRef = useRef<HTMLInputElement>(null!);

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const forgotPasswordMutation = useForgotPasswordMutation({
    onSuccess: () => {
      navigate('verify-email');
    },
    onError: () => {
      requestAnimationFrame(() => emailRef.current?.focus());
      recaptchaRef.current?.reset();
    },
  });

  const formMethods = useForm<ForgotPasswordFormValues>(
    process.env.NODE_ENV === 'development'
      ? {
          defaultValues: {
            email: 'example@gmail.com',
          },
        }
      : {},
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = formMethods;
  i18n.on('languageChanged', () => {
    reset();
  });
  const onSubmit = async (data: ForgotPasswordFormValues) => {
    if (skipRecaptcha) {
      forgotPasswordMutation.mutate({
        ...data,
      });
    } else {
      const captchaCode = await recaptchaRef.current?.executeAsync();

      forgotPasswordMutation.mutate({
        ...data,
        captchaCode: captchaCode || '',
      });
    }
  };

  const renderForgotPasswordError = () => {
    if (!forgotPasswordMutation.isError) {
      return null;
    }

    if (
      forgotPasswordMutation.error.response?.data?.type ===
      'urn:problem-type:external-change-password-not-allowed'
    ) {
      return (
        <NetworkErrorAlert networkError={forgotPasswordMutation.error}>
          {t(
            'notification.externalChangePasswordNotAllowed',
            '[1001]Forbidden operation. Please contact support!',
          )}
        </NetworkErrorAlert>
      );
    }

    return (
      <NetworkErrorAlert networkError={forgotPasswordMutation.error}>
        {t('notification.generalError', 'Something went wrong, try again…')}
      </NetworkErrorAlert>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <DevTool control={control} />
      <Box as="form" onSubmit={handleSubmit(onSubmit)} width="full">
        <VStack alignItems="stretch" spacing={6}>
          <Flex
            as="fieldset"
            direction="column"
            disabled={forgotPasswordMutation.isLoading}
          >
            <ForgotPasswordEmailField inputRef={emailRef} autoFocus />
          </Flex>

          {renderForgotPasswordError()}

          {!skipRecaptcha && (
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={grecaptchaKey}
              size="invisible"
            />
          )}

          <Button
            type="submit"
            width="full"
            isLoading={forgotPasswordMutation.isLoading}
            disabled={!isEmpty(errors)}
          >
            {t('button.recovery', 'SEND RECOVERY LINK')}
          </Button>
        </VStack>
      </Box>
    </FormProvider>
  );
};
