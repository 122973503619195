import invariant from 'tiny-invariant';

const TO_BE_REPLACED_DONT_TOUCH_THIS_MEDIABOX_ENV =
  // don't touch this literal, exactly this is replaced in the final bundle depending on the env
  'prod';

export const mediaBoxEnv: string =
  process.env.NODE_ENV !== 'production'
    ? 'local'
    : (invariant(
        'app_environment'.toUpperCase() !==
          TO_BE_REPLACED_DONT_TOUCH_THIS_MEDIABOX_ENV,
        'prod was not runtime replaced with a meaningful value',
      ),
      TO_BE_REPLACED_DONT_TOUCH_THIS_MEDIABOX_ENV);
